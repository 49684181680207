import { Box, Typography, Button } from '@mui/material';

export default function ChangeLog() {
  /**
   * Pass in an array of strings to return those strings as <li> inside <ul>
   * @param {array} arrOfStrings
   */
  const createList = (arrOfStrings) => {
    return (
      <ul>
        {arrOfStrings.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  /**
   * Creates the header for each entry. Currently supports Feature -> green or anything else -> orange
   * @param {string} date
   * @param {string} version
   * @param {string} type
   * @returns
   */
  const getHeader = (date, version, type) => {
    return (
      <>
        <Typography variant="h5" mb={1}>
          {date} - V{version}
        </Typography>
        <Button
          size="small"
          sx={{
            backgroundColor: type === 'Feature' ? '#00963c' : '#4269f5',
            '&:disabled': {
              color: 'white',
              pl: 1,
              pr: 1,
            },
          }}
          disabled
        >
          {type}
        </Button>
      </>
    );
  };

  return (
    <Box
      sx={{
        marginLeft: { xs: '10vw', sm: '35vw', md: '30vw' },
        marginRight: { xs: '0vw', sm: '5vw', md: '15vw' },
        height: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridTemplateRows: 'auto',
      }}
    >
      <Box
        sx={{
          gridColumn: '2 / 12',
          display: 'grid',
          gridTemplateRows: 'repeat(12, 1fr)',
          fontSize: { xs: 14, sm: 16 },
        }}
      >
        {/* We should be able to do something like this each time we want to add a new entry. Below is an example */}
        {/* <Box id="v1.2" sx={{gridColumn: 'span 12', mt: 5}}>
                    <Box>
                        {
                            getHeader('20 Fake, 1900', '1.2', 'Fix')
                        }
                        {createList([
                            `Add`,
                            `Bullets`,
                            `Here`
                        ])}
                    </Box>
                </Box> */}
        <Box id="v1.8" sx={{ gridColumn: 'span 12', mt: 5 }}>
          <Box>
            {getHeader('27 June, 2024', '1.8', 'Enhancement')}
            {createList([
              `The Sensor pages are more readable.`,
              `We took inspiration from Uniprot and FPbase to make Sensor components look cleaner.`,
            ])}
          </Box>
        </Box>
        <Box id="v1.7" sx={{ gridColumn: 'span 12', mt: 5 }}>
          <Box>
            {getHeader('12 June, 2024', '1.7', 'Enhancement')}
            {createList([
              `The Navigation bar is now more intuitive and user friendly in both mobile and desktop formats.`,
              `The About subsections look cleaner and are more informative.`,
            ])}
          </Box>
        </Box>
        <Box id="v1.6" sx={{ gridColumn: 'span 12', mt: 5 }}>
          <Box>
            {getHeader('19 May, 2024', '1.6', 'Enhancement')}
            {createList([
              `We've incorporated a new Tools tab in the navigation bar.`,
              `This tab links to bioinformatic tools for operator prediction (Snowprint) and genome mining (Ligify).`,
              `We also updated the styling of the Accounts page.`,
            ])}
          </Box>
        </Box>
        <Box id="v1.5" sx={{ gridColumn: 'span 12', mt: 5 }}>
          <Box>
            {getHeader('14 Sept, 2023', '1.5', 'Enhancement')}
            {createList([
              `We've added ~15 new regulators to groov!`,
              `We also fixed a bug in the New Sensor Entry Form.`,
            ])}
          </Box>
        </Box>
        <Box id="v1.4" sx={{ gridColumn: 'span 12', mt: 5 }}>
          <Box>
            {getHeader('31 May, 2023', '1.4', 'Enhancement')}
            {createList([
              `Account management is sleeker!`,
              `We've completely reconstructed the account management interface for sign ins, sign ups, and password changes.`,
            ])}
          </Box>
        </Box>
        <Box id="v1.3" sx={{ gridColumn: 'span 12', mt: 5 }}>
          <Box>
            {getHeader('27 May, 2023', '1.3', 'Enhancement')}
            {createList([
              `groovDB gets faster!`,
              `We've improved state management to deliver a more responsive user experience. You may notice that repeat page loading is now super quick.`,
            ])}
          </Box>
        </Box>
        <Box id="v1.2" sx={{ gridColumn: 'span 12', mt: 5 }}>
          <Box>
            {getHeader('21 March, 2023', '1.2', 'Enhancement')}
            {createList([
              `groovDB gets prettier!`,
              `We've restyled the Home page for a sleeker, cleaner look. The cool protein structure and new groovDB logo add some bio- flare.`,
            ])}
          </Box>
        </Box>

        <Box id="v1.1" sx={{ gridColumn: 'span 12', mt: 3 }}>
          <Box>
            {getHeader('20 Feb, 2023', '1.1', 'Feature')}
            {createList([
              `It's time for groovDB to grow!`,
              `We've added an account feature so you can contribute.`,
              `Once you have an account, you can navigate to About -> Add New Sensor to deposit your sensor into our database.`,
              `We also fixed a few bugs, added some new styling and created new backend features to support those listed above.`,
            ])}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
