import { Box, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useAddSensorStore } from '../../zustand/addSensor.store';
import useUserStore from './../../zustand/user.store';

//This component exists to seperate the submit button logic from the form
//Otherwise, state updates would cause the form to rerender
export default function AddSensorFooter({ stepValue, setStepValue }) {
  const addLigandEntry = useAddSensorStore((state) => state.addLigandEntry);
  const addOperatorEntry = useAddSensorStore((state) => state.addOperatorEntry);
  const stateData = useAddSensorStore((state) => state);
  const addError = useAddSensorStore((state) => state.addError);
  const reset = useAddSensorStore((state) => state.reset);
  const updateFormApiStatus = useAddSensorStore(
    (state) => state.updateFormApiStatus
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const user = useUserStore((context) => context.user);

  useEffect(() => {
    //Check for any error entries
    if (
      Object.keys(stateData.errors.about).length > 0 ||
      Object.keys(stateData.errors.ligands).length > 0 ||
      (Object.keys(stateData.errors.operators).length > 0 && !isDisabled)
    ) {
      setIsDisabled(true);
    } else {
      //No missing entries
      if (isDisabled) {
        //Enable button again
        setIsDisabled(false);
      }
    }
  }, [stateData]);

  /**
   * Function to find missing fields when pressing submit
   */
  const validateAllDataFull = () => {
    let errorFound = false;

    Object.entries(stateData).map(([key, value]) => {
      if (key === 'insertFormApi') {
        return;
      }

      //Entry is ligand or operator
      if (Array.isArray(value)) {
        //Loop all entries in array
        for (let pos = 0; pos < value.length; pos++) {
          Object.entries(value[pos]).map(([nestedKey, nestedValue]) => {
            //Entry not full
            if (!nestedValue && !isDisabled) {
              //Disable the button and store the error
              setIsDisabled(true);
              errorFound = true;
              addError(key, `${key}${pos + 1}${nestedKey}`, 'isEmpty');
            }
          });
        }
      } else {
        Object.entries(value).map(([otherKey, otherValue]) => {
          //Entry not full
          if (!otherValue && !isDisabled) {
            //Disable the button
            setIsDisabled(true);
            addError(key, `${key}${otherKey}`, 'isEmpty');
            errorFound = true;
          }
        });
      }
    });

    //Return if we found an error in empty entries or not
    return errorFound;
  };

  /**
   * This function processes ligands/operators to make ref_figure only a number or prepend S
   * Ex - Figure 5 or figure S5
   * It also deletes fig_type since that's not required for backend
   * @param {array} data
   */
  const processFigures = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].fig_type === 'Supplementary Figure') {
        data[i].ref_figure = `Figure S${data[i].ref_figure}`;
        delete data[i].fig_type;
        } else if (data[i].fig_type === 'Table') {
        data[i].ref_figure = `Table ${data[i].ref_figure}`;
        delete data[i].fig_type;
        } else if (data[i].fig_type === 'Supplementary Table') {
        data[i].ref_figure = `Table S${data[i].ref_figure}`;
        delete data[i].fig_type;
        } else {
        data[i].ref_figure = `Figure ${data[i].ref_figure}`;
        delete data[i].fig_type;
      }
    }

    return data;
  };

  const submitForm = async () => {
    let result = validateAllDataFull();

    if (!result) {
      const formData = {
        family: stateData.about.family.toUpperCase(),
        uniProtID: stateData.about.uniProtID,
        about: {
          about: stateData.about.about,
          accession: stateData.about.accession,
          alias: stateData.about.alias,
          mechanism: stateData.about.mechanism,
        },
        ligands: {
          data: processFigures(stateData.ligands),
        },
        operator: {
          data: processFigures(stateData.operators),
        },
        user: user.getUsername(),
        timeSubmit: Date.now(),
      };

      const resp = await fetch(
        `https://4lsuwlkqoe.execute-api.us-east-2.amazonaws.com/insertForm`,
        {
          method: 'POST',
          headers: {
            Accept: '*/*',
            Authorization: user
              .getSignInUserSession()
              .getAccessToken()
              .getJwtToken(),
          },
          body: JSON.stringify(formData),
        }
      );

      if (resp.ok) {
        reset();
        updateFormApiStatus('success');
      } else {
        const result = await resp.json();
        if (resp.status === 400) {
          updateFormApiStatus(
            'error',
            'Uh-oh, somehow the validation failed. Please check all form fields and try again.'
          );
        } else {
          updateFormApiStatus('error', result.message);
        }
      }
    }
  };

  return (
    <Box
      display="flex"
      sx={{ justifyContent: 'space-between', mx: { xs: 1, sm: 10 } }}
    >
      <Button
        onClick={() => {
          if (stepValue !== 0) {
            setStepValue(stepValue - 1);
          }
        }}
        disabled={stepValue === 0}
      >
        Previous
      </Button>
      {stepValue === 1 && (
        <Button
          onClick={() => {
            addLigandEntry();
          }}
        >
          Add New Ligand
        </Button>
      )}
      {stepValue === 2 && (
        <Button onClick={() => [addOperatorEntry()]}>Add New Operator</Button>
      )}
      {stepValue !== 2 ? (
        <Button
          onClick={() => {
            if (stepValue !== 2) {
              setStepValue(stepValue + 1);
            }
          }}
        >
          Next
        </Button>
      ) : (
        <Button
          onClick={() => {
            submitForm();
          }}
          disabled={isDisabled}
        >
          Submit
        </Button>
      )}
    </Box>
  );
}
