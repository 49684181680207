import { create } from 'zustand';
import { produce } from 'immer';


const useSearchStore = create(set => ({
    stats: {
        regulators: "Loading ...",
        ligands: "Loading ...",
    },
    data: [],

    setStats: stats => {
        set(
            produce(draft => {
                draft.stats = stats;
            })
        )
    },

    setData: data => {
        set(
            produce(draft => {
                draft.data = data;
            })
        )
    }

}));

export default useSearchStore;