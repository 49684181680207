import { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { useAddSensorStore } from './../../../../zustand/addSensor.store';
import TextInput from '../../../form-inputs/TextInput';
import {
  doiValidation,
  alphaNumericValidation,
  dnaValidation,
  numericValidation,
} from './../../../../lib/Validations';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DropdownInput from '../../../form-inputs/DropdownInput';

const fields = ['sequence', 'doi', 'fig_type', 'ref_figure', 'method'];

export default function OperatorCard({ data, index }) {
  const removeArrayEntry = useAddSensorStore((state) => state.removeArrayEntry);
  const operatorErrors = useAddSensorStore((state) => state.errors.operators);
  const popError = useAddSensorStore((state) => state.popError);

  //Remove entry
  const handleRemoval = () => {
    removeArrayEntry(index, 'operators');

    if (operatorErrors[`operators${index + 1}name`]) {
      fields.forEach((item) => {
        popError('operators', `operators${index + 1}${item}`);
      });
    }
  };

  // Collapse button
  const [open, setOpen] = useState(true);
  const collapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        gridColumn={'span 12'}
      >
        <ListItemButton onClick={collapse}>
          <ListItemText>
            <Typography
              gridColumn={'span 12'}
              sx={{ display: 'flex', alignItems: 'center' }}
            >{`Operator #${index + 1}`}</Typography>
          </ListItemText>
          {index !== 0 && (
            <IconButton
              onClick={() => {
                handleRemoval();
              }}
              style={{ marginRight: '20px' }}
            >
              <DeleteForever />
            </IconButton>
          )}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Box>

      <Box gridColumn={'span 12'}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box gridColumn={'span 12'} mb={2}>
            <TextInput
              pattern={dnaValidation}
              label={'Operator Sequence'}
              field={'operators'}
              accessKey={'sequence'}
              index={index}
            />
          </Box>
          <Box gridColumn={'span 12'} mb={2}>
            <TextInput
              pattern={doiValidation}
              label={'Operator DOI'}
              field={'operators'}
              accessKey={'doi'}
              index={index}
            />
          </Box>
          <Box gridColumn={'span 12'} display="flex" mb={2}>
            <DropdownInput
              label={'Figure Type'}
              field={'operators'}
              accessKey={'fig_type'}
              arr={'figures'}
              index={index}
            />
            <TextInput
              pattern={alphaNumericValidation}
              label={'Figure Number'}
              field={'operators'}
              accessKey={'ref_figure'}
              index={index}
              style={{ ml: 2 }}
            />
          </Box>
          <Box gridColumn={'span 12'} mb={2}>
            <DropdownInput
              label={'Experimental evidence'}
              field={'operators'}
              accessKey={'method'}
              arr={'operatorMethods'}
              index={index}
            />
          </Box>
        </Collapse>
      </Box>
    </>
  );
}
