import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';
import TextInput from '../../form-inputs/TextInput';
import { useAddSensorStore } from './../../../zustand/addSensor.store';
import {
  alphaNumericValidation,
  refseqValidation,
  aboutValidation,
} from './../../../lib/Validations';
import DropdownInput from '../../form-inputs/DropdownInput';

export default function AboutSensorTab() {
  const familyOptions = [
    'TetR',
    'LysR',
    'AraC',
    'MarR',
    'LacI',
    'GntR',
    'LuxR',
    'IclR',
    'Other',
  ];

  const mechanisms = [
    'Apo-repressor',
    'Co-repressor',
    'Apo-activator',
    'Co-activator',
  ];

  const updateField = useAddSensorStore((state) => state.updateField);
  const aboutData = useAddSensorStore((state) => state.about);

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridTemplateRows="auto"
      gridAutoRows="auto"
      rowGap={2}
      sx={{ height: '100%', mx: 3, mt: 3 }}
    >
      <Box gridColumn={'span 12'} display="flex">
        <Typography
          sx={{ fontSize: { xs: 22, md: 26 }, mb: 0, display: 'flex' }}
        >
          Basic information:
        </Typography>
      </Box>
      <Box gridColumn={'span 12'}>
        <TextInput
          pattern={alphaNumericValidation}
          label="Alias"
          field={'about'}
          accessKey={'alias'}
        />
      </Box>
      <Box gridColumn={'span 12'}>
        <TextInput
          pattern={refseqValidation}
          label="RefSeq"
          field="about"
          accessKey="accession"
        />
      </Box>
      <Box gridColumn={'span 12'}>
        <TextInput
          pattern={alphaNumericValidation}
          label="UniProt ID"
          field="about"
          accessKey="uniProtID"
        />
      </Box>
      <Box gridColumn={'span 12'}>
        <DropdownInput
          label={'Structural Family'}
          field={'about'}
          accessKey={'family'}
          arr={'familyOptions'}
        />
      </Box>
      <Box gridColumn={'span 12'}>
        <DropdownInput
          label={'Mechanism'}
          field={'about'}
          accessKey={'mechanism'}
          arr={'mechanisms'}
        />
      </Box>
      <Box gridColumn={'span 12'}>
        {/* <TextField fullWidth label="About" key="About" multiline rows={6} defaultValue={aboutData.about || ''} onChange={(e) => {
                    updateField('about', 'about', e.target.value);
                }} inputProps={{maxLength: 500}}/> */}
        <TextInput
          pattern={aboutValidation}
          label="About"
          field="about"
          accessKey="about"
          maxLength={true}
          numRows={6}
        />
      </Box>
    </Box>
  );
}
