import { Navigate, useLocation } from 'react-router-dom';
import useUserStore from '../../zustand/user.store';

export function RequireAuth({ children }) {
  const location = useLocation();
  const user = useUserStore((context) => context.user);
  if (!user) {
    return <Navigate to="/account" state={{ from: location }} replace />;
  }

  return children;
}
