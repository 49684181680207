import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogContent,
} from '@mui/material';

import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import Login from '../../Auth/Login';
import useUserStore from '../../../zustand/user.store';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';

export default function Account() {
  // Local state
  // Trying to combine these two dialogs into one state and one dialog is too slow
  // Opening/closing the dialog shows the async nature of state updates
  const [showChangePwd, setShowChangePwd] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);

  // Zustand
  const user = useUserStore((context) => context.user);

  return (
    <>
      {user ? (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
          <Box
            gridColumn={{
              xs: 'span 12',
              sm: '4 / 10',
            }}
            mt={6}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: 26,
                  sm: 36,
                },
                marginLeft: {
                  xs: 5,
                  sm: 0,
                },
              }}
            >
              {`Hey, ${user.attributes.given_name}`}
            </Typography>
          </Box>
          <Box
            gridColumn={{
              xs: 'span 10',
              sm: '4 / 10',
            }}
            mt={4}
          >
            <Button
              sx={{
                border: '1px solid blue',
                borderRadius: '3px',
                width: 200,
                marginLeft: {
                  xs: 5,
                  sm: 0,
                },
              }}
              onClick={() => setShowChangePwd(true)}
            >
              Change Password
            </Button>
          </Box>
          <Box
            gridColumn={{
              xs: 'span 10',
              sm: '4 / 10',
            }}
            mt={4}
          >
            <Button
              sx={{
                border: '1px solid red',
                borderRadius: '3px',
                width: 200,
                color: 'red',
                marginLeft: {
                  xs: 5,
                  sm: 0,
                },
              }}
              onClick={() => {
                setShowDeleteAccount(true);
              }}
            >
              Delete Account
            </Button>
          </Box>
          <Dialog
            open={showChangePwd}
            onClose={() => setShowChangePwd(false)}
            fullWidth
          >
            <DialogContent>
              <ChangePassword setShowChangePwd={setShowChangePwd} />
            </DialogContent>
          </Dialog>
          <Dialog
            open={showDeleteAccount}
            onClose={() => {
              setShowDeleteAccount(false);
            }}
          >
            <DialogContent>
              <DeleteAccount />
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <Login />
      )}
    </>
  );
}
