import { Step, Stepper, StepButton } from '@mui/material';

const steps = ['About', 'Ligands', 'Operators'];

export default function AddSensorStepper({ stepValue, setStepValue }) {
  const handleStepChange = (step) => {
    setStepValue(step);
  };

  return (
    <Stepper nonLinear activeStep={stepValue} alternativeLabel>
      {steps.map((item, index) => (
        <Step key={item}>
          <StepButton
            color="inherit"
            onClick={() => {
              handleStepChange(index);
            }}
            disableRipple
          >
            {item}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}
