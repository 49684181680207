import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import AboutSensorTab from './tabViews/AboutSensorTab';
import OperatorSensorTab from './tabViews/OperatorSensorTab';
import LigandSensorTab from './tabViews/LigandSensorTab';
import Preview from './Preview';
import AddSensorFooter from './AddSensorFooter';
import AddSensorStepper from './AddSensorStepper';

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

const steps = ['About', 'Ligands', 'Operators'];

export default function AddSensor({ user }) {
  // mobile-friendly theme setting
  const [view, setView] = useState('form');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));

  const [stepValue, setStepValue] = useState(0);

  const TabPanel = ({ children, value, index }) => {
    return (
      <Box role="tabpanel" hidden={value !== index}>
        {value === index && <Box>{children}</Box>}
      </Box>
    );
  };

  const getForm = () => {
    return (
      <Box
        sx={{
          ml: { xs: 2, sm: 10 },
          mr: 2,
          mt: 5,
          gridColumn: { xs: 'span 12', sm: 'span 6' },
        }}
      >
        <AddSensorStepper stepValue={stepValue} setStepValue={setStepValue} />
        <TabPanel value={stepValue} index={0}>
          <AboutSensorTab />
        </TabPanel>
        <TabPanel value={stepValue} index={1}>
          <LigandSensorTab />
        </TabPanel>
        <TabPanel value={stepValue} index={2}>
          <OperatorSensorTab />
        </TabPanel>
        <AddSensorFooter stepValue={stepValue} setStepValue={setStepValue} />
      </Box>
    );
  };

  const getPreview = () => {
    return (
      <Box
        sx={{
          mr: { xs: 2, sm: 10 },
          ml: 2,
          mt: 5,
          gridColumn: { xs: 'span 12', sm: 'span 6' },
        }}
      >
        <Preview />
      </Box>
    );
  };

  const setMobileView = () => {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: '0px',
          backgroundColor: 'white',
          zIndex: 4,
          paddingTop: '10px',
          borderTop: '1px solid black',
          height: '60px',
          width: '100vw',
          textAlign: 'center',
        }}
      >
        <Button onClick={() => setView('form')}>Form</Button>
        <Button onClick={() => setView('preview')}>Preview</Button>
      </Box>
    );
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridTemplateRows="auto"
      gridAutoRows="auto"
      sx={{ height: 'calc(100% + 90px)' }}
    >
      {mobile ? (
        <>
          {getForm()}

          {getPreview()}
        </>
      ) : view == 'form' ? (
        <>
          {getForm()}

          {setMobileView()}
        </>
      ) : (
        <>
          {getPreview()}

          {setMobileView()}
        </>
      )}
    </Box>
  );
}
