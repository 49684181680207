/* eslint-disable */ // WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsConfig = {
    Auth: {
      mandatorySignIn: true,
  
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_JO965QtEP',
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '6m910frpkh3cucjplcsh8ptb7j',
    },
  };
export default awsConfig;
