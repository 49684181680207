import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import NavigationBar from './Components/NavigationBar.js';
import AddSensor from './Components/addSensor/AddSensor.js';
import Home from './Components/Home.js';
import RegFamilyTiles from './Components/RegFamilyTiles.js';

import Account from './Components/About/Account/Account.js';
import Admin from './Components/About/Admin/Admin.js';
import About from './Components/About/About.js';
import Tools from './Components/Tools.js';

import { Routes, Route } from 'react-router-dom';

import './css/App.css';
import { useMediaQuery, useTheme } from '@mui/material';

import { RequireAuth } from './Components/Auth/RequireAuth';
import { RequireAdminAuth } from './Components/Auth/RequireAdminAuth';
import { Amplify, Auth } from 'aws-amplify';
import useUserStore from './zustand/user.store';

import awsConfig from './aws-exports.js';

Amplify.configure(awsConfig);

export default function App() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const setUser = useUserStore(context => context.setUser);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then((data) => {
      if(data) {
        setUser(data);
      }
    })
    .catch(() => {}); //Catch - user is not authenticated on app boot up
  }, [])

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridTemplateRows={isSmallScreen ? `56px` : `64px`}
      gridAutoRows="auto"
      sx={{ height: '100%' }}
    >
      <Box gridColumn="span 12">
        <NavigationBar />
      </Box>
      <Box gridColumn="span 12">
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />

          <Route path="/database/*" element={<RegFamilyTiles />} />

          <Route path="/account" element={
            <Account />
          } />

          <Route
            path="/admin"
            element={
              <RequireAdminAuth>
                <Admin />
              </RequireAdminAuth>
            }
          />

          <Route path="/about/*" element={<About />} />
          <Route path="/tools" element={<Tools />} />

          <Route
            path="/addSensor"
            element={
              <RequireAuth>
                <AddSensor />
              </RequireAuth>
            }
          />

        </Routes>
      </Box>
    </Box>
  );
}
