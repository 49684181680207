import React, { useState } from 'react';

import ProtvistaStructure from 'protvista-structure';

import {
  Box,
  Grid,
  Typography,
  Paper,
  Pagination,
  Stack,
  Link,
} from '@mui/material';

export default function ProteinStructure(props) {
  const structureIDs = props.structureIDs;

  //index of structures currently displayed
  const [structureIndex, setStructureIndex] = useState(1);

  const changeStructure = (event, value) => {
    setStructureIndex(value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container style={{ width: '100%' }}>
        {/* Component Title */}
        <Grid item xs={12}>
          <Typography
            component="div"
            style={{ marginLeft: '5%', fontSize: 28, fontWeight: 300 }}
          >
            Structure
          </Typography>
        </Grid>

        {/* Protein Structure */}
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ height: '500px' }}>
            {typeof structureIDs[structureIndex - 1] !== 'undefined' ? (
              <protvista-structure
                height="500px"
                accession={props.uniprotID}
                structureID={structureIDs[structureIndex - 1]}
                hide-table
              />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <img
                  style={{
                    height: '250px',
                    marginTop: '35px',
                    marginBottom: '40px',
                  }}
                  alt="Greyed out biosensor structure"
                  src={'/TetRNoStructure.png'}
                />
                <h2>No structure available</h2>
              </div>
            )}

            <Typography
              component="div"
              sx={{ textAlign: 'center', fontSize: 24, fontWeight: 400 }}
            >
              {typeof structureIDs[structureIndex - 1] !== 'undefined' ? (
                structureIDs[structureIndex - 1]
              ) : ("No structure")
            }
            </Typography>
          </Paper>
        </Grid>

        {/* Pagination */}
        <Grid item xs={12} mt={7} mb={3}>
          <Stack spacing={2} alignItems="center">
            <Pagination
              count={props.structureIDs.length}
              page={structureIndex}
              onChange={changeStructure}
              size="small"
            />
          </Stack>
        </Grid>

        {/* Reference */}

        <Grid item xs={12} mt={1}>
          <Grid container>
            <Grid item xs={5} textAlign="right">
              <Typography
                component="span"
                width="100px"
                sx={{
                  fontSize: { xs: 14, sm: 16, md: 16 },
                  paddingRight: '15px',
                  borderRight: '2px solid #0084ff',
                }}
              >
                <b>ID</b>
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign="left" ml={'15px'}>
            
            {typeof structureIDs[structureIndex - 1] !== 'undefined' ? (

              structureIDs[structureIndex - 1].length == 4 ? (
                <Link
                  href={
                    'https://www.ebi.ac.uk/pdbe/entry/pdb/' +
                    structureIDs[structureIndex - 1]
                  }
                  target="_blank"
                  style={{ textDecoration: 'None', color: '#243fab' }}
                >
                  <Typography
                    component="span"
                    width="100px"
                    sx={{ fontSize: { xs: 14, sm: 16, md: 16 } }}
                  >
                    {structureIDs[structureIndex - 1]}
                  </Typography>
                </Link>
              ) : (
                <Link
                  href={'https://alphafold.ebi.ac.uk/entry/' + props.uniprotID}
                  target="_blank"
                  style={{ textDecoration: 'None', color: '#243fab' }}
                >
                  <Typography
                    component="span"
                    width="100px"
                    sx={{ fontSize: { xs: 14, sm: 16, md: 16 } }}
                  >
                    {structureIDs[structureIndex - 1]}
                  </Typography>
                </Link>
              )
            ) : (
                  <Typography
                    component="span"
                    width="100px"
                    sx={{ fontSize: { xs: 14, sm: 16, md: 16 } }}
                  >
                    No structure
                  </Typography>
                )
            }

            </Grid>
          </Grid>
        </Grid>

        {/* Method */}

        <Grid item xs={12} mt={1}>
          <Grid container>
            <Grid item xs={5} textAlign="right">
              <Typography
                component="span"
                width="100px"
                sx={{
                  fontSize: { xs: 14, sm: 16, md: 16 },
                  paddingRight: '15px',
                  borderRight: '2px solid #0084ff',
                }}
              >
                <b>Method</b>
              </Typography>
            </Grid>

            <Grid item xs={6} textAlign="left" ml={'15px'}>
              <Typography
                component="span"
                width="100px"
                sx={{ fontSize: { xs: 14, sm: 16, md: 16 } }}
              >
                {structureIDs[structureIndex - 1].length == 4
                  ? 'X-ray'
                  : 'Predicted'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
window.customElements.define('protvista-structure', ProtvistaStructure);
