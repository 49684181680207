import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import LigandCard from './cards/LigandCard';
import { useAddSensorStore } from './../../../zustand/addSensor.store';

const ligandMethod = [
  'EMSA',
  'DNase footprinting',
  'Isothermal titration calorimetry',
  'Surface plasmon resonance',
  'Synthetic regulation',
  'Fluorescence polarization',
];

export default function LigandSensorTab() {
  const ligandsData = useAddSensorStore((state) => state.ligands);

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridTemplateRows="auto"
      gridAutoRows="auto"
      rowGap={1}
      sx={{ height: '100%', mx: 3, mt: 3 }}
    >
      <Box gridColumn={'span 12'} display="flex">
        <Typography
          sx={{ fontSize: { xs: 22, md: 26 }, mb: 1, display: 'flex' }}
        >
          Ligand-binding information:
        </Typography>
      </Box>
      {ligandsData.map((item, index) => (
        <LigandCard data={item} index={index} key={index} />
      ))}
    </Box>
  );
}
