import { useState } from 'react';
import { Box, Typography, TextField, Select, MenuItem } from '@mui/material';
import { useAddSensorStore } from './../../../zustand/addSensor.store';
import OperatorCard from './cards/OperatorCard';

export default function OperatorSensorTab() {
  const operatorData = useAddSensorStore((state) => state.operators);

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridTemplateRows="auto"
      gridAutoRows="auto"
      rowGap={1}
      sx={{ height: '100%', mx: 3, mt: 3 }}
    >
      <Box gridColumn={'span 12'} display="flex">
        <Typography
          sx={{ fontSize: { xs: 22, md: 26 }, mb: 1, display: 'flex' }}
        >
          DNA-binding information:
        </Typography>
      </Box>
      {operatorData.map((item, index) => (
        <OperatorCard data={item} index={index} key={index} />
      ))}
    </Box>
  );
}
