import React, { useEffect } from 'react';

import GenomeContext from './GenomeContext.js';
import LigandViewer from './LigandViewer.js';
import ReferenceViewer from './ReferenceViewer.js';
import SeqViewer from './SeqViewer.js';
import DNAbinding from './DNAbinding.js';
import MetadataTable from './MetadataTable.js';
import ProteinStructure from './ProteinStructure';

import { Box, Grid, Typography } from '@mui/material';

import useSensorStore from '../../zustand/sensor.store.js';

import {getFirstTwoWords} from '../../lib/utils.js';

export default function SensorPage({ family, sensorID, isAdmin, user }) {
  // access data from zustand store
  const setSensorData = useSensorStore((context) => context.setSensorData);
  const sensorData = useSensorStore((context) => context.sensorData[sensorID]);

  const placement = {
    ligMT: 0,
    ligMB: 0,
  };

  useEffect(() => {
    // Only fetch if the data isn't already loaded in the zustand store
    if (sensorData === undefined) {
      if (family && sensorID) {
        fetch(
          `https://4lsuwlkqoe.execute-api.us-east-2.amazonaws.com${
            isAdmin ? '/getProcessedTemp' : '/getSensor'
          }?family=${family?.toUpperCase()}&sensorID=${sensorID}`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: isAdmin
                ? user.getSignInUserSession().getIdToken().getJwtToken()
                : null,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setSensorData(data.uniprotID, data);
          });
      }
    }
  }, [sensorID]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={3}
        sx={{ minHeight: '100vh', mt: 2 }}
        justifyContent="center"
      >
        {/* Alias  */}
        <Grid item xs={12}>
          <Typography
            component="div"
            gutterBottom
            sx={{
              fontSize: { xs: 30, sm: 55 },
              textAlign: 'center',
              fontWeight: 300,
            }}
          >
            {sensorData === undefined ? 'loading...' : sensorData.alias}
          </Typography>
        </Grid>

        {/* About  */}
        <Grid item xs={3} />
        <Grid item xs={12} sm={6} alignItems="center" sx={{ mb: 2 }}>
          <Typography
            component="div"
            gutterBottom
            sx={{ fontSize: { xs: 16, sm: 18, md: 20 }, textAlign: 'center' }}
          >
            {sensorData === undefined ? 'loading...' : sensorData.about}
          </Typography>
        </Grid>
        <Grid item xs={3} />

        {/* Metadata Table */}
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6} mb={4}>
          {sensorData === undefined ? (
            'loading...'
          ) : (
            <MetadataTable
              tableData={{
                'Regulation Type': { name: sensorData.regulationType },
                'Uniprot ID': {
                  name: sensorData.uniprotID,
                  link: {
                    url: `https://www.uniprot.org/uniprot/${sensorData.uniprotID}`,
                  },
                },
                'RefSeq ID': {
                  name: sensorData.accession,
                  link: {
                    url: `https://www.ncbi.nlm.nih.gov/protein/${sensorData.accession}`,
                  },
                },
                'KEGG ID': {
                  name: sensorData.keggID ? sensorData.keggID : 'None',
                  ...(sensorData.keggID !== 'None' && {
                    link: {
                      url: `https://www.genome.jp/dbget-bin/www_bget?${sensorData.keggID}`,
                    },
                  }),
                },
                Organism: {
                  name: getFirstTwoWords(sensorData.organism),
                  link: {
                    url: `https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?lvl=0&id=${sensorData.organismID}`,
                  },
                },
              }}
            />
          )}
        </Grid>
        <Grid item xs={3} />

        {/* Ligands  */}
        <Grid item xs={12} sm={10} md={5} lg={4} mb={5}>
          {sensorData === undefined ? (
            'loading...'
          ) : (
            <LigandViewer
              ligand={sensorData.ligands}
              key={new Date().getTime()}
              placement={placement}
            />
          )}
        </Grid>

        {/* Structure  */}
        <Grid item xs={10} sm={10} md={5} lg={4} mb={5}>
          {sensorData === undefined ? (
            'loading...'
          ) : (
            <ProteinStructure
              key={new Date().getTime()}
              structureIDs={[
                ...sensorData.structures,
                `AF-${sensorData.uniprotID}-F1`,
              ]}
              uniprotID={sensorData.uniprotID}
            />
          )}
        </Grid>

        {/* Sequence  */}
        <Grid item xs={12} sm={10} md={10} lg={8} mb={5}>
          {sensorData === undefined ? (
            'loading...'
          ) : (
            <SeqViewer sequence={sensorData.sequence} />
          )}
        </Grid>

        {/* Operator */}
        <Grid item xs={12} sm={10} md={10} lg={8} mb={5}>
          {sensorData === undefined ? (
            'no operator set'
          ) : (
            <DNAbinding operator_data={sensorData.operators} />
          )}
        </Grid>

        {/* Genome Context  */}
        <Grid item xs={12} sm={10} md={10} lg={8} mb={5}>
          {sensorData === undefined ? (
            'loading...'
          ) : (
            <GenomeContext
              sensor={sensorData}
              key={new Date().getTime()}
              alias={sensorData.alias}
            />
          )}
        </Grid>

        {/* References */}
        <Grid item xs={12} sm={10} md={10} lg={8}>
          {sensorData === undefined ? (
            'loading...'
          ) : (
            <ReferenceViewer
              references={sensorData.fullReferences}
              key={new Date().getTime()}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
