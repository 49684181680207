import { Box, TextField, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import ForgotPasswordConfirm from './ForgotPasswordConfirm';

export default function ForgotPassword({
  showForgotPassword,
  setShowForgotPassword,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [userName, setUsername] = useState(null);
  const [codeSent, setCodeSent] = useState(false);

  const sendForgotCode = () => {
    // Reject empty field
    if (!userName) {
      enqueueSnackbar('Please enter a username.', {
        variant: 'error',
        preventDuplicate: true,
      });
    } else {
      // Open second dialog
      Auth.forgotPassword(userName)
        .then(() => setCodeSent(true))
        .catch((err) =>
          enqueueSnackbar(err.message, {
            variant: 'error',
            preventDuplicate: true,
          })
        );
    }
  };

  useEffect(() => {
    // If the first open dialog has been closed
    // Close the second one which is laying on top of it
    if (codeSent && !showForgotPassword) {
      setCodeSent(false);
    }
  }, [showForgotPassword]);

  const closeAllDialogs = () => {
    // Close the first open dialog
    setShowForgotPassword(false);
  };

  return (
    <Box>
      {codeSent ? (
        <ForgotPasswordConfirm
          userName={userName}
          closeAllDialogs={closeAllDialogs}
        />
      ) : (
        <>
          <Typography>Enter your username</Typography>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            sx={{ marginTop: 3 }}
            onChange={(e) => {
              e.preventDefault();
              setUsername(e.target.value);
            }}
          />
          <Button
            sx={{
              border: '1px solid blue',
              borderRadius: '3px',
              width: '100%',
              marginTop: 3,
            }}
            onClick={() => sendForgotCode()}
          >
            Send code
          </Button>
          <Button
            sx={{
              color: '#1976d2',
              mt: 3,
            }}
            align="center"
            fullWidth
            onClick={() => closeAllDialogs()}
          >
            Return to Login
          </Button>
        </>
      )}
    </Box>
  );
}
